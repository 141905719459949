
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP');

body {
  margin: 0;
  font-family: 'Noto Serif JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blogDate {
  text-align: end;
  color: darkgray;
}
